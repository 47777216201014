import React from 'react';
import { Card } from 'react-bootstrap';

import '../css/components/EventCard.css';

export const EventCard = ({ details, muted }) => {
  return (
    <Card className={muted ? "muted": ""}>
      <Card.Body>
        <div className="event-left text-center">
          <div className="event-title">
            {details.title}
          </div>
        </div>
        <div className="event-right text-center">
          <div className="event-time">
            {details.time}
          </div>
          <div className="event-date">
            {details.date}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
