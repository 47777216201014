import React from 'react';

import '../css/App.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { EventCard } from '../components/EventCard';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';

function IndexPage() {
  const events = [
    {
      "title": "Weekly Sync",
      "time": "7:00pm PDT",
      "date": "August 11",
    },
    {
      "title": "Weekly Sync",
      "time": "7:00pm PDT",
      "date": "August 18"
    }
  ];
  const nextMeeting = new Date("8/11/2021");
  const month = nextMeeting.getMonth()
  const date = nextMeeting.getDate()
  const year = nextMeeting.getFullYear()
  const nextMeetingFormatted = `${month}-${date}-${year}`
  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>The Midnight Café</title>
      </Helmet>
      <Container>
        <Row>
          <Col offset xl={{
            span: 8,
            offset: 2
          }}>
            <Container className="header pt-5 text-center">
              <h1>The Midnight Café</h1>
              <p>An online community for developers.</p>
            </Container>
            <Container className="mt-5">
              <h3>Events</h3>
              <Row className="event-cards">
                {events.map((details, index) =>
                  <Col key={`event-card-${index}`} xl={5}>
                    <EventCard details={details} muted={index > 0} />
                  </Col>
                )}
              </Row>
            </Container>
            <Container className="mt-5">
              <h3>Presenter Signup Form</h3>
              <Form name={`Presenter Form ${nextMeetingFormatted}`} method="post" data-netlify="true">
                <Form.Control type="hidden" name="form-name" value={`Presenter Form ${nextMeetingFormatted}`} />
                <Form.Group className="mt-3">
                  <Form.Label>What is your display name?</Form.Label>
                  <Form.Control type="text" name="display-name" required />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>What would you like to share?</Form.Label>
                  <Form.Control type="text" name="what-to-share" required />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Do you need more than 5 minutes to talk about it?</Form.Label>
                  <Form.Check type="radio" name="require-more-time" label="Yes" value="Yes" />
                  <Form.Check type="radio" name="require-more-time" label="No" value="No" />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Do you need to share your screen?</Form.Label>
                  <Form.Check type="radio" name="require-share-screen" label="Yes" value="Yes" />
                  <Form.Check type="radio" name="require-share-screen" label="No" value="No" />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Where can people go to learn more? (e.g. GitHub link, social media, etc.)</Form.Label>
                  <Form.Control type="text" name="learn-more" required />
                </Form.Group>
                <Button type="submit" className="mt-3 mb-5" style={{
                  backgroundColor: "#420543",
                  border: 0
                }}>
                  Signup
                </Button>
              </Form>
            </Container>
            <Container className="mb-5">
              <a href="https://discord.gg/574HWYr2P4">Click here for Discord invite</a>
              <div className="w-100">
                <iframe title="Discord Widget" src="https://discord.com/widget?id=872018099841400842&theme=dark" width="350" height="400" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default IndexPage;
